import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyC_aUSqiiRihEgrsDiHD3I_nF6LOlWLjdE",
    authDomain: "defend-resource-reservation.firebaseapp.com",
    projectId: "defend-resource-reservation",
    storageBucket: "defend-resource-reservation.appspot.com",
    messagingSenderId: "770817290251",
    appId: "1:770817290251:web:7e8c9fa799029fa988570e"
}

export const VAPID_KEY = "BKHd5c9weJm2eFmYgG-YVhkNFbDobAz_aQ37pXqN4iKosm4iGUrd9kETZZTbkf_IlYotIQ1CfLtoVejX55-jBaY";


const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
    console.log("Got on message " + JSON.stringify(payload))
    new Notification(payload.notification.title, {body: payload.notification.body} );
});

export const requestToken = async () => {
    let currentToken = await getToken(messaging, {vapidKey: VAPID_KEY})
    if (currentToken) {
        console.log('current token for client: ', currentToken);
        return currentToken
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        return undefined;
    }
};
