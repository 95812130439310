import React, { useState, useMemo } from "react";
import _ from "lodash";
import { TextField } from "@mui/material";

const CommentBox = ({ initalText, onUpdate }) => {
  const [text, setText] = useState(initalText || "");

  const debounce = useMemo(() => _.debounce(async (e) => {
    onUpdate(e.target.value);
  }, 1000), [onUpdate]);
  
  const handleInputChange = (e) => {
    setText(e.target.value);
    debounce(e)
  };

  return (
    <TextField
      label="Comments"
      multiline
      fullWidth
      minRows={1}
      maxRows={4}
      value={text}
      variant="standard"
      onChange={handleInputChange}
    />
  );
};

export default CommentBox;
