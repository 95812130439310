export default class ReservationService {
    // static socketUrl = "ws://127.0.0.1:8787/reservation";
    static socketUrl = "wss://resource-backend-cf.nutty.workers.dev/reservation";

    socket;
    onOpen;
    onMessage;
    onError;
    onClose;

    constructor(onOpen, onMessage, onError, onClose) {
        this.onOpen = onOpen;
        this.onMessage = onMessage;
        this.onError = onError;
        this.onClose = onClose;
    }

    connect() {
        this.socket = new WebSocket(ReservationService.socketUrl);

        this.socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            this.onMessage(message)
        };

        this.socket.onopen = () => {
            this.onOpen();
        };

        this.socket.onerror = () => {
            this.onError()
        };

        this.socket.onclose = (event) => {
            this.onClose(event)
        };

    }

    sendName(userName) {
        if (this.isSocketConnected()) {
            this.socket.send(JSON.stringify({type: "name", userName}));
        }
    }

    sendNotificationToken(firebaseToken) {
        if (this.isSocketConnected()) {
            this.socket.send(JSON.stringify({type: "notificationToken", token: firebaseToken}));
        }
    }

    isSocketConnected() {
        return this.socket && this.socket.readyState === 1;
    }

    reserveResource(name) {
        if (this.isSocketConnected()) {
            this.socket.send(JSON.stringify({type: "reserve", name}));
        }
    };

    releaseResource(name) {
        if (this.isSocketConnected()) {
            this.socket.send(JSON.stringify({type: "release", name}));
        }
    };

    notify(name) {
        if (this.isSocketConnected()) {
            this.socket.send(JSON.stringify({type: "notify", name}));
        }
    };

    unNotify(name) {
        if (this.isSocketConnected()) {
            this.socket.send(JSON.stringify({type: "unnotify", name}));
        }
    };

    setComment(name, comment) {
        if (this.isSocketConnected()) {
            this.socket.send(JSON.stringify({type: "comment", name: name, comment: comment}));
        }
    };


    close() {
        if (this.socket && typeof this.socket.close === "function") {
            this.socket.close();
        }
    }
}