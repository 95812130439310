// src/components/LandingPage.js

import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";

const LandingPage = ({ onJoin }) => {
  const [name, setName] = useState("");

  return (
    <div className="landing-page">
      <Typography variant="h4">Defend Resource Reservation</Typography>
      <Typography variant="subtitle1">Enter your name to continue:</Typography>
      <TextField
        label="Your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        size="small"
        fullWidth
        margin="normal"
      />
      <Button
        onClick={() => onJoin(name)}
        disabled={!name.trim()}
        variant="contained"
        color="primary"
      >
        Go
      </Button>
    </div>
  );
};

export default LandingPage;
