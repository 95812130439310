import React, {useState, useEffect, useRef} from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Tooltip,
    TextField,
    Grid,
    Divider, Chip, Box
} from "@mui/material";
import ConfirmationModal from "./ConfirmationModal";
import CommentBox from "./CommentBox";
import _ from "lodash";
import ReservationService from "../services/ReservationService";
import NotificationsButton from "./NotificationsButton";

const ReservationPage = ({userName, resetName}) => {
    const [resources, setResources] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [resourceToRelease, setResourceToRelease] = useState(null);
    const resService = useRef(null);
    const [error, setError] = useState(null);
    const [reconnecting, setReconnecting] = useState(false);
    const resourcesRef = useRef(resources);
    const firebaseToken = useRef(resources);

    const onConnect = () => {
        setError(undefined)
        resService.current.sendName(userName)
        if (firebaseToken.current){
            resService.current.sendNotificationToken(firebaseToken.current)
        }
    }

    const onMessage = (message) => {
        setError(undefined)
        if (message.type === "resources") {
            console.log("resources " + message.data)
            setResources(message.data);
        }

    }

    const onError = () => {
        setError("WebSocket error occurred.");
    }

    const onClose = (event) => {
        console.log("got close " + JSON.stringify(event))
        if (!event.wasClean) {
            attemptReconnect("WebSocket connection closed unexpectedly.");
        }
    }

    const initializeWebSocket = () => {
        if (resService.current) {
            resService.current.close()
        }
        resService.current = new ReservationService(onConnect, onMessage, onError, onClose);
        resService.current.connect(userName);
    };

    useEffect(() => {
        resourcesRef.current = resources;
    }, [resources]);

    const attemptReconnect = (ogError) => {
        setError(ogError)
        console.log("Attempt recon")
        if (!reconnecting) {
            setReconnecting(true);
            setTimeout(() => {
                console.log("actual recon")
                setError(`Error:${ogError} Reconnecting...`);
                setReconnecting(false);
                initializeWebSocket();
            }, 5000); // Try reconnecting in 5 seconds
        }
    };

    useEffect(() => {
        initializeWebSocket();

        return () => {
            resService.current.close()
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setResources((prevResources) =>
                prevResources.map((resource) => {
                    if (resource.reserved) {
                        return {
                            ...resource,
                            duration: formatDuration(resource.reservedAt),
                        };
                    }
                    return resource;
                })
            );
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const requestNotificationPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
            console.log("Notification permission not granted.");
        }
    };

    const reserveResource = (name) => {
        resService.current.reserveResource(name)
    };

    const releaseResource = (name) => {
        resService.current.releaseResource(name)
    };

    const releaseOtherResource = (name) => {
        setShowModal(false);
        releaseResource(name);
    };

    const toggleNotify = (resource) => {
        requestNotificationPermission();
        if (resService.current) {
            if (resource.notifications && resource.notifications.includes(userName)) {
                resService.current.unNotify(resource.name)
            } else {
                resService.current.notify(resource.name)
            }
        }
    };

    const formatDuration = (reservedAt) => {
        const now = new Date();
        const reservationDate = new Date(reservedAt);
        const diffInSeconds = Math.max(0, Math.floor((now - reservationDate) / 1000));

        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;

        return `${hours > 0 ? `${hours}h ` : ""}${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
    };

    const getWaitingText = (resource) => {
        var users = resource.notifications?.length ?? 0

        return users > 0 ? `with ${users} waiting` : "";
    };

    const getWaitingUsers = (resource) => {
        if (resource.notifications?.length === 0) {
            return "No one waiting";
        }

        return resource.notifications.join(", ");
    };

    const onCommentUpdate = (resource, text) => {
        console.log("text" + text)
        resService.current.setComment(resource.name, text)
    };

    const onFirebaseTokenAcquired = (token) => {
        console.log("Got TB token " + token)
        firebaseToken.current = token
        if (resService.current) {
            resService.current.sendNotificationToken(token)
        }
    };

    let groupedResources = _.groupBy(resources, res => res.environment);
    return (
        <div className="reservation-page">
            <Typography variant="h4">Resource Reservation</Typography>
            <Typography variant="subtitle1">Welcome, {userName}!</Typography>
            <Button
                onClick={resetName}
                variant="outlined"
                size="small"
            >
                Reset Name
            </Button>
            <NotificationsButton onTokenAcquired={onFirebaseTokenAcquired}/>
            {/*todo come up with a  better way to force people to enable notifications or but them if they have not */}
            {error && (
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            )}
            {resources && (
                _.sortBy(Object.keys(groupedResources), res => res)
                    .map(k => ({name: k, values: groupedResources[k]}))
                    .map(group => (
                        <>
                            {/*<Typography variant="body2" mt={2} mb={2} ml={2} color="text.secondary">*/}
                            {/*    {group.name}*/}
                            {/*</Typography>*/}
                            {/*<Divider textAlign="left">{group.name}</Divider>*/}
                            <Box my={2}>
                                <Divider>
                                    <Chip label={group.name}/>
                                </Divider>
                            </Box>

                            <Grid container spacing={2} columns={2}>
                                {group.values.map((resource) => (
                                    <Grid item xs={2} md={1}>
                                        <Card key={resource.name} variant="outlined">
                                            <CardContent>
                                                <Typography variant="h6">{resource.name}</Typography>
                                                <Typography color="textSecondary">
                                                    {resource.reserved ? (
                                                        <>
                                                            <span>
                                                              Reserved by {resource.reservedBy} for {formatDuration(resource.reservedAt)}
                                                            </span>
                                                            <Tooltip title={getWaitingUsers(resource)}>
                                                                <span> {getWaitingText(resource)}</span>
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        "Available"
                                                    )}
                                                </Typography>
                                                {resource.reserved && resource.reservedBy === userName && (
                                                    <CommentBox
                                                        initalText={resource.comment}
                                                        onUpdate={(text) => onCommentUpdate(resource, text)}
                                                    />
                                                )}
                                                {resource.reserved && resource.reservedBy !== userName && (
                                                    <TextField
                                                        label="Comments"
                                                        multiline
                                                        fullWidth
                                                        disabled
                                                        minRows={1}
                                                        maxRows={4}
                                                        placeholder="No comment"
                                                        value={resource.comment}
                                                        variant="standard"
                                                    />
                                                )}
                                            </CardContent>
                                            <CardActions>
                                                {!resource.reserved && (
                                                    <Button
                                                        size="small"
                                                        onClick={() => reserveResource(resource.name)}
                                                    >
                                                        Reserve
                                                    </Button>
                                                )}
                                                {resource.reserved && resource.reservedBy === userName && (
                                                    <Button
                                                        size="small"
                                                        onClick={() => releaseResource(resource.name)}
                                                    >
                                                        Release
                                                    </Button>
                                                )}
                                                {resource.reserved && resource.reservedBy !== userName && (
                                                    <>
                                                        <Button
                                                            size="small"
                                                            onClick={() => {
                                                                setShowModal(true);
                                                                setResourceToRelease(resource.name);
                                                            }}
                                                        >
                                                            Release other's reservation
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            onClick={() => toggleNotify(resource)}
                                                        >
                                                            {resource.notifications && resource.notifications.includes(userName)
                                                                ? "Remove Notification"
                                                                : "Notify when free"}
                                                        </Button>
                                                    </>
                                                )}
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </>

                    )))}
            <ConfirmationModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={() => releaseOtherResource(resourceToRelease)}
            />
        </div>
    )
};

export default ReservationPage;


