import React, { useState } from "react";
import "./App.css";
import LandingPage from "./components/LandingPage";
import ReservationPage from "./components/ReservationPage";
import { Container } from "@mui/material";
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  const [name, _setName] = useState(localStorage.getItem("userName") || "");

  const setName = (newName) => {
    localStorage.setItem("userName", newName);
    _setName(newName);
  };

  const resetName = () => {
    localStorage.removeItem("userName");
    _setName("");
  };

  return (
    <Container maxWidth="xl" className="App">
      <ErrorBoundary>
        {!name ? (
          <LandingPage onJoin={(name) => setName(name)} />
        ) : (
          <ReservationPage userName={name} resetName={resetName} />
        )}
      </ErrorBoundary>
    </Container>
  );
}

export default App;
